<template>
  <div class="main index" v-if="!isLoading" ref="element">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Assessment Report</b></h1>
      </div>
      <div class="column bar">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Assessment</h3>
            </li>
            <li>
              <router-link :to="{ name: `reportAssessment` }">
                <h3 class="is-size-6">Assessment Report</h3>
              </router-link>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">
                {{ report.assessment }} - NIT: {{ report.nit }}
              </h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <b-modal :active.sync="isShowImageActive" :width="900">
      <div class="box">
        <img v-lazy="activeImageUrl" width="900" />
      </div>
    </b-modal>
    <div>
      <div class="columns">
        <div class="column is-12">
          <b-tabs type="is-hcc" v-model="activeTab">
            <!-- For proctoring image -->
            <b-tab-item label="Proctoring">
              <div class="columns is-multiline header-proctoring">
                <div class="column is-10">
                  <label class="title">Summary</label>
                </div>
                <div class="column is-2">
                  <b-field grouped position="is-right">
                    <b-button
                      type="is-hcc"
                      @click="downloadImageZip(report.date)"
                    >
                      Download All Zip
                    </b-button>
                  </b-field>
                </div>
                <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Screen Capture</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">
                        {{ report.screenCapture }} images
                      </label>
                    </span>
                  </span>
                </div>
                <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Photo</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">{{ report.camera }} photos</label>
                    </span>
                  </span>
                </div>
              </div>
              <div class="columns">
                <span class="column is-12">
                  <b-table
                    :data="proctoring && proctoring.data ? proctoring.data : []"
                    :current-page.sync="currentPage"
                    paginated
                    backend-pagination
                    :loading="tableLoading"
                    :total="
                      proctoring && proctoring.meta.total
                        ? proctoring.meta.total
                        : 0
                    "
                    :per-page="perPage"
                    @page-change="onPageChange"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    backend-sorting
                    :default-sort-direction="defaultSortOrder"
                    :default-sort="[sortField, sortOrder]"
                    @sort="onSort"
                  >
                    <b-table-column
                      field="index"
                      numeric
                      width="20"
                      label="No"
                      v-slot="props"
                    >
                      {{ props.index + proctoring.meta.from }}
                    </b-table-column>
                    <b-table-column field="url" label="Image" v-slot="props">
                      <img v-lazy="props.row.url" width="100" />
                    </b-table-column>
                    <b-table-column
                      field="created_at"
                      label="Capture At"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.createdAt }}
                    </b-table-column>
                    <b-table-column
                      field="type"
                      label="Type"
                      sortable
                      width="300"
                      v-slot="props"
                    >
                      {{ props.row.type }}
                    </b-table-column>
                    <b-table-column field="action" v-slot="props">
                      <b-button
                        class="button-action"
                        @click="activeModal(props.row.url)"
                      >
                        View
                      </b-button>
                      <b-button
                        class="button-action"
                        @click="
                          download(
                            props.row.date,
                            props.row.image,
                            props.row.type
                          )
                        "
                      >
                        Download
                      </b-button>
                    </b-table-column>
                    <template slot="bottom-left">
                      {{
                        proctoring && proctoring.meta.from
                          ? proctoring.meta.from
                          : 0
                      }}
                      -
                      {{
                        proctoring && proctoring.meta.to
                          ? proctoring.meta.to
                          : 0
                      }}
                      from
                      {{
                        proctoring && proctoring.meta.total
                          ? proctoring.meta.total
                          : 0
                      }}
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon icon="frown" size="is-large"></b-icon>
                          </p>
                          <p>Data not found.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </span>
              </div>
            </b-tab-item>

            <!-- For proctoring copy/paste data -->
            <b-tab-item label="Proctoring (Copy/Paste)">
              <div class="columns is-multiline header-proctoring">
                <div class="column is-10">
                  <label class="title">Summary</label>
                </div>
                <div class="column is-2"></div>
                <!-- <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Screen Capture</label>
                    </span>
                    <span class="column is-3">
                      <label class="label"
                        >{{ report.screenCapture }} images</label
                      >
                    </span>
                  </span>
                </div>
                <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Photo</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">{{ report.camera }} photos</label>
                    </span>
                  </span>
                </div> -->
              </div>
              <div class="columns is-multiline">
                <span class="column is-4">
                  <b-field>
                    <b-input
                      v-model="searchProctoring"
                      placeholder="Search..."
                      type="search"
                      icon="search"
                    ></b-input>
                  </b-field>
                </span>
                <span class="column is-8">
                  <b-field grouped position="is-right">
                    <b-select v-model="perPageProctoring">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </b-select>
                  </b-field>
                </span>
                <span class="column is-12">
                  <b-table
                    :data="searchProctoringRecord"
                    :current-page.sync="currentPageProctoring"
                    paginated
                    backend-pagination
                    :loading="tableLoading"
                    :total="searchProctoringRecord.length"
                    :per-page="perPageProctoring"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    detailed
                    detail-key="id"
                  >
                    <b-table-column
                      field="index"
                      numeric
                      width="20"
                      label="No"
                      v-slot="props"
                    >
                      {{
                        perPageProctoring * (currentPageProctoring - 1) +
                          1 +
                          props.index
                      }}
                    </b-table-column>
                    <b-table-column
                      field="date"
                      label="Date"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.date }}
                    </b-table-column>
                    <b-table-column
                      field="action"
                      label="Action"
                      sortable
                      width="300"
                      v-slot="props"
                    >
                      {{ props.row.action }}
                    </b-table-column>
                    <b-table-column
                      field="text"
                      label="Text"
                      sortable
                      width="300"
                      v-slot="props"
                    >
                      <template v-if="props.row.text.length > 30">
                        {{ props.row.text.substring(0, 30) }}...
                      </template>
                      <template v-else>
                        {{ props.row.text }}
                      </template>
                    </b-table-column>

                    <!-- For detail data -->
                    <template slot="detail" slot-scope="props">
                      <div class="columns is-multiline">
                        <!-- For Date -->
                        <div class="column is-2"><strong>Date :</strong></div>
                        <div class="column is-10">{{ props.row.date }}</div>

                        <!-- For Action -->
                        <div class="column is-2"><strong>Action :</strong></div>
                        <div class="column is-10">{{ props.row.action }}</div>

                        <!-- For Text -->
                        <div class="column is-2"><strong>Text :</strong></div>
                        <div class="column is-10">{{ props.row.text }}</div>
                      </div>
                    </template>

                    <!-- For empty data -->
                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon icon="frown" size="is-large"></b-icon>
                          </p>
                          <p>Data not found.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </span>
              </div>
            </b-tab-item>

            <!-- For proctoring section looping -->
            <b-tab-item
              v-for="(section, indexSection) in report.answer"
              :key="indexSection"
              :label="'Section ' + (indexSection + 1)"
            >
              <div class="columns is-multiline header-proctoring">
                <div class="column is-10">
                  <label class="title">Summary</label>
                </div>
                <div class="column is-2">
                  <b-field grouped position="is-right">
                    <b-button
                      type="is-hcc"
                      @click="downloadExcel(report.key[indexSection])"
                    >
                      Download Xls
                    </b-button>
                  </b-field>
                </div>
                <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Score</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">
                        {{ report.totalPoint[indexSection] }}/{{
                          report.maxPoint[indexSection]
                        }}
                      </label>
                    </span>
                  </span>
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Duration</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">
                        {{ report.time[indexSection] }}s
                      </label>
                    </span>
                  </span>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <b-table :data="section">
                    <b-table-column
                      field="index"
                      label="Group No"
                      v-slot="props"
                    >
                      {{ props.row.group }}
                    </b-table-column>
                    <b-table-column
                      field="index"
                      label="Question Number (Random)"
                      v-slot="props"
                    >
                      {{ props.row.questionNoRandom }}
                    </b-table-column>
                    <b-table-column
                      field="index"
                      label="Question Number (Real)"
                      v-slot="props"
                    >
                      {{ props.row.questionNoReal }}
                    </b-table-column>
                    <b-table-column
                      field="question"
                      label="Question"
                      v-slot="props"
                    >
                      {{ props.row.question }}
                    </b-table-column>
                    <b-table-column
                      field="answer"
                      label="Answer"
                      width="400"
                      v-slot="props"
                    >
                      <span class="word-wrap">{{ props.row.answer }}</span>
                    </b-table-column>
                    <b-table-column
                      field="time"
                      label="Submited At"
                      numeric
                      v-slot="props"
                    >
                      {{ props.row.time }}
                    </b-table-column>
                    <b-table-column
                      field="point"
                      label="Score"
                      numeric
                      v-slot="props"
                    >
                      {{ props.row.point }}
                    </b-table-column>
                  </b-table>
                </div>
                <!-- <b-table
                  :data="section"
                  :loading="true"
                  :total="
                section.length
              "
                ></b-table>-->
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFullPage: true,
      activeTab: 0,
      isLoading: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortField: 'createdAt',
      sortOrder: 'DESC',
      currentPage: 1,
      perPage: 10,
      searchProctoring: null,
      currentPageProctoring: 1,
      perPageProctoring: 10,
      page: 1,
      search: null,
      surveys: [],
      name: '',
      report: null,
      proctoring: [],
      isShowImageActive: false,
      activeImageUrl: null,
      tableLoading: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async downloadExcel(sectionId) {
      var content = []
      content.sectionId = sectionId
      content.reportId = this.$route.params.reportId
      await this.$store.dispatch('downloadExcel', content).then(response => {
        if (response == 400) {
          this.danger('download failed')
        } else {
          this.success('download complete')
        }
      })
    },
    async downloadImageZip(date) {
      var content = []
      content.date = date
      content.nit = this.report.nit
      content.assessmentId = this.report.assessmentId
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store.dispatch('downloadImageZip', content).then(response => {
        if (response == 400) {
          this.danger('download failed')
        } else {
          this.success('download complete')
        }
        loadingComponent.close()
      })
    },
    async download(date, image, type) {
      var content = []
      content.date = date
      content.image = image
      content.type = type
      content.nit = this.report.nit
      content.assessmentId = this.report.assessmentId
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store.dispatch('downloadImage', content).then(response => {
        if (response == 400) {
          this.danger('download failed')
        } else {
          this.success('download complete')
        }
        loadingComponent.close()
      })
    },
    activeModal(url) {
      this.activeImageUrl = url
      this.isShowImageActive = true
    },
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getReport',
        this.$route.params.reportId
      )
      this.report = response.data
      await this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder
      )
      this.isLoading = false
    },
    async fetchDataProctoring(perPage, page, sortField, sortOrder) {
      if (this.report != null) {
        var content = []
        content.nit = this.report.nit
        content.assessmentId = this.report.assessmentId
        content.perPage = perPage
        content.page = page
        content.sortField = sortField
        content.sortOrder = sortOrder
        const responseProctoring = await this.$store.dispatch(
          'getProctoring',
          content
        )
        this.proctoring = responseProctoring
      }
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
  },
  computed: {
    searchProctoringRecord() {
      if (this.searchProctoring) {
        return this.report.proctoringRecord.filter(proctoring => {
          return this.searchProctoring
            .toLowerCase()
            .split(' ')
            .every(v => proctoring.text.toLowerCase().includes(v))
        })
      } else {
        return this.report.proctoringRecord
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-proctoring {
  //   background-color:
  border-bottom: 1px solid #00a3a3;
}
.tabs ul {
  border-bottom-color: #00a3a3 !important;
}
.button-action {
  border: none !important;
  color: #00a3a3;
}
.word-wrap {
  word-break: break-all;
}
</style>
